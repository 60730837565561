<template>
  <div id="page">
    <div class="container">
      <div class="header font-weight-bold font-size-xl">
        <slot name="header"></slot>
      </div>

      <div class="body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageLayout"
}
</script>

<style lang="scss" scoped>
#page {
  margin: 0 auto;
  max-width: 960px;
  padding: (map-get($spacings, "seperator") * 2) 0;

  .header {
    margin-bottom: map-get($spacings, "seperator");
  }

  .body {

  }
}
</style>
